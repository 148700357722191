import '../../styles/login/index.scss';

const url = window.location.href;

const showPasswordButton = document.getElementById('showPasswordButton');

function changeInputType(event) {
  const inputPassword = event.target.parentNode.querySelector('input');
  const eyeButton =
    event.target.parentNode.querySelector('div.eye-icon') ||
    event.target.parentNode.querySelector('div.eye-crossed-out-icon');

  if (inputPassword.type === 'password') {
    inputPassword.type = 'text';
    eyeButton.classList.remove('eye-icon');
    eyeButton.classList.add('eye-crossed-out-icon');
  } else {
    inputPassword.type = 'password';
    eyeButton.classList.remove('eye-crossed-out-icon');
    eyeButton.classList.add('eye-icon');
  }
}

if (showPasswordButton) {
  showPasswordButton.onclick = changeInputType;
}

function hasNumber(value) {
  return /\d/.test(value);
}

function hasLetter(value) {
  return /[a-zA-Z]/.test(value);
}

function hasSpecialCharacter(value) {
  return !/^[A-Za-z0-9 ]+$/.test(value);
}

function isValidValue(value, passwordId, repeatPasswordId) {
  let isValid = true;
  const firstValidation = document.getElementById('at_least_8_characters');
  const secondValidation = document.getElementById('at_least_one_letter');
  const thirdValidation = document.getElementById('at_least_one_digit');
  const fourthValidation = document.getElementById('at_least_one_special_character');
  const fifthValidation = document.getElementById('passwords_match');
  const passwordInput = document.getElementById(passwordId);
  const repeatPasswordInput = document.getElementById(repeatPasswordId);

  if (value.length >= 8) {
    firstValidation.classList.remove('empty-icon');
    firstValidation.classList.add('filled-check-icon');
  } else {
    firstValidation.classList.add('empty-icon');
    firstValidation.classList.remove('filled-check-icon');
    isValid = false;
  }

  if (hasLetter(value)) {
    secondValidation.classList.remove('empty-icon');
    secondValidation.classList.add('filled-check-icon');
  } else {
    secondValidation.classList.add('empty-icon');
    secondValidation.classList.remove('filled-check-icon');
    isValid = false;
  }

  if (hasNumber(value)) {
    thirdValidation.classList.remove('empty-icon');
    thirdValidation.classList.add('filled-check-icon');
  } else {
    thirdValidation.classList.add('empty-icon');
    thirdValidation.classList.remove('filled-check-icon');
    isValid = false;
  }

  if (value && hasSpecialCharacter(value)) {
    fourthValidation.classList.remove('empty-icon');
    fourthValidation.classList.add('filled-check-icon');
  } else {
    fourthValidation.classList.add('empty-icon');
    fourthValidation.classList.remove('filled-check-icon');
    isValid = false;
  }

  if (value && repeatPasswordInput.value === passwordInput.value) {
    fifthValidation.classList.remove('empty-icon');
    fifthValidation.classList.add('filled-check-icon');
  } else {
    fifthValidation.classList.remove('filled-check-icon');
    fifthValidation.classList.add('empty-icon');
    isValid = false;
  }

  return isValid;
}

function addValidationForChangePassword(passwordId, repeatPasswordId) {
  const eyePasswordButton = document.createElement('div');
  eyePasswordButton.classList.add('eye-icon');
  eyePasswordButton.onclick = changeInputType;
  const eyeRepeatPasswordButton = document.createElement('div');
  eyeRepeatPasswordButton.classList.add('eye-icon');
  eyeRepeatPasswordButton.onclick = changeInputType;
  const passwordContainer = document.getElementById('password_input');
  passwordContainer.appendChild(eyePasswordButton);
  const repeatPasswordContainer = document.getElementById('repeat_password_input');
  repeatPasswordContainer.appendChild(eyeRepeatPasswordButton);
  const passwordInput = document.getElementById(passwordId);
  const repeatPasswordInput = document.getElementById(repeatPasswordId);
  const activateButton = document.getElementById('activate_button');
  const termConfirm = document.getElementById('term_confirm');

  const listener = value => {
    const isValid = termConfirm
      ? isValidValue(value, passwordId, repeatPasswordId) && termConfirm.checked
      : isValidValue(value, passwordId, repeatPasswordId);

    if (isValid) {
      activateButton.removeAttribute('disabled');
    } else {
      activateButton.setAttribute('disabled', 'true');
    }
  };

  passwordInput.addEventListener('input', event => listener(event.target.value));
  repeatPasswordInput.addEventListener('input', () => listener(passwordInput.value));

  if (termConfirm) {
    termConfirm.addEventListener('input', () => listener(passwordInput.value));
  }
}

if (url.includes('users/activate')) {
  addValidationForChangePassword(
    'activate_user_changePassword_password',
    'activate_user_changePassword_repeatPassword'
  );
}

if (url.includes('/reset-password')) {
  addValidationForChangePassword('change_password_password', 'change_password_repeatPassword');
}
